.login_container {
  @apply flex flex-col justify-center items-center h-full w-full gap-14;
}

.login_title {
  @apply text-h5 text-font-textcolor1 font-semibold sm:mb-4;
  letter-spacing: 0px;

  // @media screen and (min-width: 1536px) {
  //   @apply text-2xl;
  // }

  // @media screen and (min-width: 1800px) {
  //   @apply text-h6 mb-8;
  // }
}

.login_form_separates {
  @apply flex flex-col justify-center items-center;
}

.login_card {
  @apply flex flex-col tracking-tighter;

  @media screen and (min-width: 1400px) {
    @apply w-[400px];
  }

  @media screen and (min-width: 1800px) {
    @apply w-[500px];
  }
}

.login_box {
  @apply grid grid-cols-2 justify-items-end items-center w-full h-full;
}

.login_section {
  @apply relative h-screen bg-contain bg-top bg-no-repeat;
}

.login_section_text {
  @apply text-font-textcolor3 text-body1 font-normal text-left w-full;
  letter-spacing: 0.02em;
}

.login_details_separates {
  @apply w-full mt-[18px] mb-[18px] xl:mb-[22px] grid grid-cols-1 gap-4;

  @media screen and (min-width: 1800px) {
    @apply gap-[22px];
  }
}

.login_separates {
  @apply w-full flex justify-center items-center;
}

.login_pw {
  @apply text-primary-main text-body1 2xl:text-base w-full flex justify-end items-center;
  letter-spacing: 0.02em;
}

.create_text {
  @apply text-body1 2xl:text-base mt-1;
  letter-spacing: 0.02em;
}

.login_page_title {
  @apply text-h6 2xl:text-h4 font-bold mt-0 w-full flex justify-center;
  letter-spacing: -0.5px;

  @media screen and (min-width: 1800px) {
    @apply text-h3;
  }
}
